export class KeysHolder {
    constructor(name) {
        this.name = name
    }

    async requestKey() {
        let pass = prompt('Введите ключ для работы с разделом ' + this.name + '"', 'NisseTeam2')
        //let pass = 'NisseTeam2';
        pass && sessionStorage.setItem("ad-ref", pass)
        const enc = new TextEncoder();
        this.key = await window.crypto.subtle.importKey(
            'raw',
            await crypto.subtle.digest('SHA-256', enc.encode(pass)),
            { name: 'AES-CBC' },
            false,
            ["encrypt", "decrypt"],
        );
    }

    async checkOrRequestKey() {
        if(sessionStorage.getItem("ad-ref")){
            const enc = new TextEncoder();
            this.key = await window.crypto.subtle.importKey(
                'raw',
                await crypto.subtle.digest('SHA-256', enc.encode(sessionStorage.getItem("ad-ref"))),
                { name: 'AES-CBC' },
                false,
                ["encrypt", "decrypt"],
            );
        }
        if(!this.key) await this.requestKey()
        return !!this.key
    }

    async encrypt(obj, iv){
        const jsonEnc = new TextEncoder().encode(JSON.stringify(obj))
        if(iv) {
            iv = this.stringToUint8(iv)
        } else {
            iv = window.crypto.getRandomValues(new Uint8Array(16));
        }
        console.log(jsonEnc, 'jsonEnc')
        return {
            data: this.stringifyUint8(await window.crypto.subtle.encrypt(
                    {
                        name: "AES-CBC",
                        iv,
                    },
                    this.key, 
                    jsonEnc,
                )), 
            addit: this.stringifyUint8(iv.buffer)}
    }

    stringifyUint8(arr){
        let view = new Uint32Array(arr);
        let res = ''
        for(let i = 0 ; i < view.length; i++){
            res += this.toFixedRadix36Length7(view[i])
        }
        return res
    }

    stringToUint8(str){
        const chunkSize = 7
        const strLen = str.length
        if(strLen % chunkSize !== 0) throw new Error("strLen % chunkSize !== 0")
        let res = new Uint32Array(strLen / chunkSize);
        for(let i = 1; i <= strLen / chunkSize; i++) {
            let chunk = str.substring((i-1) * chunkSize, i*chunkSize)
            let val = Number.parseInt(chunk, 36)
            res[i-1] = val
        }
        return res
    }

    toFixedRadix36Length7(uint64){
        const val = uint64.toString(36)
        switch (val.length){
            case 1: return '000000' + val
            case 2: return '00000' + val
            case 3: return '0000' + val
            case 4: return '000' + val
            case 5: return '00' + val
            case 6: return '0' + val
            case 7: return val
            default: throw new Error("input is larger than uint 64")
        }
    }

    async decrypt(objStr, iv){
        const a = await window.crypto.subtle.decrypt(
            {
                name: "AES-CBC",
                iv: this.stringToUint8(iv),
            },
            this.key, 
            this.stringToUint8(objStr)
        )
        return JSON.parse(new TextDecoder().decode(a))
    }

}

export const projectKeyHolder = new KeysHolder('Проекты');