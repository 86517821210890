<template>
  <v-container>
    <h1>
      <span>Редактирование проводки</span>
    </h1>

    <section v-if="apiLoaded" class="form-wrap">
      <v-row>
        <v-col cols="12">
          <v-select
            label="Год по бюджету*"
            v-model="wData.data.budgetYear"
            :items="years"
            dense
            outlined
            :disabled="yearDisabled"
            @change="
              entriesDict = entries.filter(
                (e) => e.year == wData.data.budgetYear
              );
              year = wData.data.budgetYear;
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            label="Проект"
            :items="entriesDict"
            item-text="name"
            item-value="id"
            return-object
            v-model="wData.data.project"
            outlined
            dense
            clearable
            @change="
              if (wData.data.project) {
                wData.data.budgetYear = wData.data.project.year;
                year = wData.data.budgetYear;
                yearDisabled = true;
              } else {
                wData.data.budgetYear = new Date().getFullYear();
                year = wData.data.budgetYear;
                yearDisabled = false;
              }
              if (wData.data.project) {
                funds = fundsDict[wData.data.wiringTypeDir].filter(
                  (item) => item.project == true
                );
              } else {
                funds = fundsDict[wData.data.wiringTypeDir].filter(
                  (item) => item.project == false
                );
              }
            "
          ></v-select>
        </v-col>
      </v-row>

      <!-- поле Организация - поменять экспертов на Л-стар + клиребл -->
      <v-row>
        <v-col cols="12">
          <v-select
            label="Организация*"
            :items="ourOrgs"
            item-text="shortName"
            item-value="id"
            return-object
            v-model="wData.data.organization"
            dense
            outlined
            clearable
            @change="
              if (wData.data.organization) {
                if (wData.data.organization.accounts != null) {
                  accountsDict = [...wData.data.organization.accounts];
                } else {
                  accountsDict = [
                    'Необходимо заполнить номер счета организации.',
                  ];
                }
              } else {
                accountsDict = [];
                // wData.data.accountNumber = null;
              }
            "
          ></v-select>
        </v-col>
      </v-row>

      <!-- TODO Справочник счета НЦИ - добавить / связать с выбором организации (куда нам приходит), если не выбрана организация заблокировать поле -->
      <v-row>
        <v-col cols="12">
          <v-select
            :items="accountsDict"
            v-model="wData.data.accountNumber"
            label="Номер счета"
            dense
            outlined
            :disabled="!wData.data.organization"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select
            label="Ответственный*"
            :items="experts"
            item-text="fullName"
            item-value="id"
            return-object
            v-model="wData.data.expert"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h4>тип проводки*</h4>
          <v-radio-group
            row
            v-model="wData.data.wiringType"
            required
            :rules="rules.select"
          >
            <v-radio :value="1" label="Планируемая"></v-radio>
            <v-radio :value="2" label="Фактическая"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- ------------------------------------------------------------------------------- -->

      <v-row style="margin-bottom: 40px">
        <v-col cols="12">
          <h4>Приход/Расход*</h4>
          <v-radio-group
            row
            v-model="wData.data.wiringTypeDir"
            @change="
              if (wData.data.project) {
                funds = fundsDict[wData.data.wiringTypeDir].filter(
                  (item) => item.project == true
                );
              } else {
                funds = fundsDict[wData.data.wiringTypeDir].filter(
                  (item) => item.project == false
                );
              }
              wData.data.funds = ' ';
              wData.data.article = ' ';
              wData.data.paymentMethod = ' ';
              articles = [];
              paymentMethods = [];
            "
            :rules="[(v) => (v != null && !!v) || 'is required']"
            required
            solo
          >
            <v-radio value="expences" label="Расход"></v-radio>
            <v-radio value="income" label="Доход"></v-radio>
            <v-radio
              value="internalRedistribution"
              label="Перераспределение внутреннее"
              :disabled="!!wData.data.project"
            ></v-radio>
            <v-radio value="securityDeposit" label="Обеспечение"></v-radio>
            <v-radio
              value="externalRedistribution"
              label="Перераспределение внешнее"
              :disabled="!!wData.data.project"
            ></v-radio>
            <v-radio
              value="reserve"
              label="Резерв"
              :disabled="!!wData.data.project"
            ></v-radio>
            <v-radio
              value="nonfund"
              label="Нефондовые"
              :disabled="!!wData.data.project"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            label="Фонд*"
            :items="funds"
            item-text="fund"
            item-value="id"
            return-object
            v-model="wData.data.funds"
            dense
            outlined
            required
            :rules="rules.select"
            @focus="
              if (wData.data.funds == ' ') {
                wData.data.funds = null;
              }
            "
            @change="
              articles = articlesDict[wData.data.funds.id];
              paymentMethods = [];
              wData.data.article = ' ';
              wData.data.paymentMethod = ' ';
              paymentMethods = [];
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            label="Статья*"
            :items="articles"
            item-text="article"
            item-value="id"
            return-object
            v-model="wData.data.article"
            dense
            outlined
            required
            :rules="rules.select"
            @focus="
              if (wData.data.article == ' ') {
                wData.data.article = null;
              }
            "
            @change="
              wData.data.paymentMethod = ' ';
              paymentMethods = [
                { id: 1, text: 'Наличный', value: wData.data.article.cash },
                {
                  id: 2,
                  text: 'Безналичный',
                  value: wData.data.article.cashless,
                },
              ].filter((el) => el.value == true);
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row style="margin-bottom: 40px">
        <v-col cols="12">
          <v-select
            label="Способ оплаты*"
            :items="paymentMethods"
            item-text="text"
            item-value="id"
            return-object
            v-model="wData.data.paymentMethod"
            dense
            outlined
            required
            :rules="rules.select"
            @focus="
              if (wData.data.paymentMethod == ' ') {
                wData.data.paymentMethod = null;
              }
            "
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <date-field
            v-bind:label="
              wData.data.wiringType != 1
                ? 'Дата оплаты*'
                : 'Дата оплаты (планируемая)*'
            "
            :value="wData.data.paymentDate"
            @input="wData.data.paymentDate = $event"
            v-bind:min-date="
              wData.data.wiringType == 1 ? getTodayDateString() : '2000-01-01'
            "
            max-date="2029-12-31"
          ></date-field>
        </v-col>
      </v-row>

      <register-select-field
        field-title="Договор"
        dialog-title="Выбор договора"
        :reg-config="customerContractsRegisterConfig"
        page-size="10"
        default-tab="0"
        :text-function="(item) => item.theme || ''"
        text-search-attribute="theme"
        :value-object="wData.data.contractId"
        @select="
          wData.data.contractId = $event;
          $forceUpdate();
        "
        @clear="
          wData.data.contractId = null;
          $forceUpdate();
        "
        not-null="true"
      >
      </register-select-field>

      <v-row>
        <v-col cols="12">
          <register-select-field
            field-title="Контрагент*"
            dialog-title="Выбор контрагент"
            :reg-config="counterpartiesRegisterConfig"
            page-size="10"
            default-tab="0"
            :text-function="(item) => item.fullName || ''"
            text-search-attribute="fullName"
            :value-object="wData.data.contractor"
            @select="
              wData.data.contractor = $event;
              $forceUpdate();
            "
            @clear="
              wData.data.contractor = null;
              $forceUpdate();
            "
            not-null="true"
          >
          </register-select-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Сумма (руб.)*"
            v-model="wData.data.sum"
            dense
            outlined
            :rules="[
              (v) =>
                v == null ||
                v.length === 0 ||
                !!normalizeNumber(v, 0, 1e13, 2) ||
                'Введите корректную сумму',
            ]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="wData.data.comment"
            outlined
            label="Примечание"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-alert v-show="errorMessage" outlined dense type="error">{{
        errorMessage
      }}</v-alert>

      <div class="mt-6">
        <v-btn outlined onclick="history.back()">Отменить</v-btn>
        <v-btn
          @click="
            if (editFormValidator(wData.data)) {
              editWiring(
                wData.id,
                wData.data.budgetYear,
                wData.data,
                wData.addit
              );
            } else {
              errorMessage = 'Не все обязательные поля заполнены';
            }
          "
          >Сохранить проводку</v-btn
        >
      </div>
    </section>
  </v-container>
</template>

<script>
import {
  projectsRegisterConfig,
  customerContractsRegisterConfig,
  customerCounterpartiesRegisterConfig,
  supplierCounterpartiesRegisterConfig,
  supplierContractsRegisterConfig,
} from './register/RegisterConfigs';
import RegisterSelectField from '@/components/register/RegisterSelectField';
import {
  articlesDict,
  fundsDict,
  wiringTypeDirDict,
  editFormValidator,
} from '@/components/register/WireDict';
import { wGetById, wDecOne, wUpdate } from '@/modules/Wirings';
import { loadDataToObject } from '@/modules/CommonUtils';
import { ourOrgsAccountsDict } from '@/modules/NSI';
import api from '@/modules/api';
import { projectKeyHolder } from '@/modules/Projects';
import { normalizeNumber } from '@/modules/CommonUtils';
import DateField from '@/components/elements/DateField';

export default {
  name: 'WiringEditComponent',
  props: {},
  components: { RegisterSelectField, DateField },
  data() {
    return {
      // Mock Article
      articles: [],
      // Mock Fund
      funds: [],
      paymentMethods: [],
      wData: {},
      entries: [],
      entriesDict: [],
      errorMessage: '',
      id: 0,
      dialog: false,
      years: [2020, 2021, 2022, 2023, 2024],
      apiLoaded: false,
      date: this.date,
      dates: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      year: new Date().getFullYear(),
      yearDisabled: false,
      projectsRegisterConfig: {
        tabs: [{ title: 'Проекты', config: projectsRegisterConfig }],
      },
      counterpartiesRegisterConfig: {
        tabs: [
          {
            title: 'Заказчики',
            config: customerCounterpartiesRegisterConfig,
          },
          {
            title: 'Подрядчики',
            config: supplierCounterpartiesRegisterConfig,
          },
        ],
      },
      customerContractsRegisterConfig: {
        tabs: [
          { title: 'С заказчиками', config: customerContractsRegisterConfig },
          { title: 'С поставщиками', config: supplierContractsRegisterConfig },
        ],
      },
      config: projectsRegisterConfig,
      articlesDict: articlesDict,
      fundsDict: fundsDict,
      wiringTypeDirDict: wiringTypeDirDict,
      accountsDict: [],
      rules: {
        select: [(v) => !!v || 'Обязательное поле.'],
      },
    };
  },
  watch: {
    async year(newValue, oldValue) {
      console.log('year changed', this.year, newValue, oldValue);
      this.loadPage();
    },
  },
  methods: {
    getTodayDateString: () => {
      return `${new Date().getFullYear()}-${(
        '0' +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`;
    },
    editFormValidator: editFormValidator,
    editWiring: async (id, year, data, addit) => {
      if (
        data.wiringTypeDir != null &&
        data.article != null &&
        data.funds != null &&
        data.paymentMethod != null &&
        data.wiringType != null
      ) {
        const res = await wUpdate(id, year, data, addit);
        if (res === 200) {
          history.back();
        }
      } else {
        alert(
          'Не все поля заполнены, должны быть заполнены: \nТип проводки, Приход/Расход, Фонд, Статья, Способ оплаты'
        );
      }
    },
    loadData: loadDataToObject,
    normalizeNumber: normalizeNumber,
    async loadPage() {
      await projectKeyHolder.checkOrRequestKey();
      let portion = (await api.get(this.config.apiRestEndpoint + this.year))
        .payload;
      console.log('portion: ', portion);
      for (let i = 0; i < portion.length; i++) {
        let decrypted = await projectKeyHolder.decrypt(
          portion[i].data,
          portion[i].addit
        );
        decrypted.id = portion[i].id;
        portion[i] = decrypted;
      }
      this.entries = portion.filter((e) => {
        return e ? true : false;
      });
      this.entries.sort((a, b) => {
        switch (this.sortField) {
          case 'id':
            return (this.sortAsc ? 1 : -1) * (a.id - b.id);
          case 'number':
            return (this.sortAsc ? 1 : -1) * (a.number > b.number ? 1 : -1);
          case 'status':
            return (this.sortAsc ? 1 : -1) * (a.status > b.status ? 1 : -1);
          case 'offerExpert.fullName':
            return (
              (this.sortAsc ? 1 : -1) *
              (a.offerExpert?.fullName > b?.offerExpert.fullName ? 1 : -1)
            );
        }
      });
      this.entriesDict = this.entries;
      console.log('entries loaded', this.entries, this.entriesDict);
    },
  },

  async beforeMount() {
    const res = await wGetById(this.$route.params.id);
    this.id = this.$route.params.id;
    if (this.$route.query.year != undefined) {
      this.year =
        this.$route.query.year != undefined
          ? this.$route.query.year
          : new Date().getFullYear();
      // if (!this.wData.data.budgetYear) {
      // }
    }
    console.log('this id', this.id);
    this.wData = await wDecOne(res);
    console.log('wData', this.wData);
    if (this.wData.data.budgetYear) {
      this.year = this.wData.data.budgetYear;
    }
    this.date =
      this.wData.data.paymentDate == 2022
        ? this.date
        : this.wData.data.paymentDate;

    await Promise.all([
      this.loadData('/supmain/contracts', 'contracts', true),
      this.loadData(
        '/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:nullfalse',
        'counterparties',
        true
      ),
      this.loadData(
        '/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:true',
        'ourOrgs',
        true
      ),
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        'experts',
        true
      ),
    ]);

    // this.ourOrgs = this.ourOrgs.map((e) => {
    //   if (!e.shortName) e.shortName = e.fullName;
    //   return e;
    // });
    this.ourOrgs = ourOrgsAccountsDict;
    await this.loadPage();

    console.log('Entries: ', this.entries);
    this.entriesDict = this.entries;
    console.log('EntriesDict: ', this.entriesDict);
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        return e;
      });
    };
    this.experts = getFullName(this.experts);
    if (this.wData.data.wiringTypeDir) {
      // this.funds = fundsDict[this.wData.data.wiringTypeDir];
      if (this.wData.data.project) {
        this.funds = fundsDict[this.wData.data.wiringTypeDir].filter(
          (item) => item.project == true
        );
      } else {
        this.funds = fundsDict[this.wData.data.wiringTypeDir];
      }
    } else {
      this.wData.data.wiringTypeDir = null;
      this.wData.data.funds = null;
      this.wData.data.article = null;
      this.wData.data.paymentMethod = null;
    }
    if (this.wData.data.funds) {
      this.articles = articlesDict[this.wData.data.funds.id];
    }
    if (this.wData.data.article && this.articles[0]) {
      this.paymentMethods = [
        { id: 1, text: 'Наличный', value: this.wData.data.article.cash },
        {
          id: 2,
          text: 'Безналичный',
          value: this.wData.data.article.cashless,
        },
      ];
    } else {
      this.paymentMethods = [];
    }
    console.log('wData b4: ', this.wData);
    if (this.wData.data.project && this.wData.data.project.year) {
      this.wData.data.budgetYear = this.wData.data.project.year;
      this.yearDisabled = true;
    }
    if (
      this.wData.data.organization != null &&
      this.wData.data.organization.accounts
    ) {
      this.accountsDict = [...this.wData.data.organization.accounts];
    } else {
      this.accountsDict = ['Необходимо заполнить номер счета организации.'];
    }
    this.apiLoaded = true;
  },
};
</script>

<style scoped>
.add-link {
  display: inline-block;
  margin-bottom: 30px;
}

.form-wrap h4 {
  text-transform: uppercase;
}
</style>
