var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_c('span',[_vm._v("Редактирование проводки")])]),(_vm.apiLoaded)?_c('section',{staticClass:"form-wrap"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Год по бюджету*","items":_vm.years,"dense":"","outlined":"","disabled":_vm.yearDisabled},on:{"change":function($event){_vm.entriesDict = _vm.entries.filter(
              function (e) { return e.year == _vm.wData.data.budgetYear; }
            );
            _vm.year = _vm.wData.data.budgetYear;}},model:{value:(_vm.wData.data.budgetYear),callback:function ($$v) {_vm.$set(_vm.wData.data, "budgetYear", $$v)},expression:"wData.data.budgetYear"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Проект","items":_vm.entriesDict,"item-text":"name","item-value":"id","return-object":"","outlined":"","dense":"","clearable":""},on:{"change":function($event){if (_vm.wData.data.project) {
              _vm.wData.data.budgetYear = _vm.wData.data.project.year;
              _vm.year = _vm.wData.data.budgetYear;
              _vm.yearDisabled = true;
            } else {
              _vm.wData.data.budgetYear = new Date().getFullYear();
              _vm.year = _vm.wData.data.budgetYear;
              _vm.yearDisabled = false;
            }
            if (_vm.wData.data.project) {
              _vm.funds = _vm.fundsDict[_vm.wData.data.wiringTypeDir].filter(
                function (item) { return item.project == true; }
              );
            } else {
              _vm.funds = _vm.fundsDict[_vm.wData.data.wiringTypeDir].filter(
                function (item) { return item.project == false; }
              );
            }}},model:{value:(_vm.wData.data.project),callback:function ($$v) {_vm.$set(_vm.wData.data, "project", $$v)},expression:"wData.data.project"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Организация*","items":_vm.ourOrgs,"item-text":"shortName","item-value":"id","return-object":"","dense":"","outlined":"","clearable":""},on:{"change":function($event){if (_vm.wData.data.organization) {
              if (_vm.wData.data.organization.accounts != null) {
                _vm.accountsDict = [].concat( _vm.wData.data.organization.accounts );
              } else {
                _vm.accountsDict = [
                  'Необходимо заполнить номер счета организации.' ];
              }
            } else {
              _vm.accountsDict = [];
              // wData.data.accountNumber = null;
            }}},model:{value:(_vm.wData.data.organization),callback:function ($$v) {_vm.$set(_vm.wData.data, "organization", $$v)},expression:"wData.data.organization"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.accountsDict,"label":"Номер счета","dense":"","outlined":"","disabled":!_vm.wData.data.organization},model:{value:(_vm.wData.data.accountNumber),callback:function ($$v) {_vm.$set(_vm.wData.data, "accountNumber", $$v)},expression:"wData.data.accountNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Ответственный*","items":_vm.experts,"item-text":"fullName","item-value":"id","return-object":"","dense":"","outlined":""},model:{value:(_vm.wData.data.expert),callback:function ($$v) {_vm.$set(_vm.wData.data, "expert", $$v)},expression:"wData.data.expert"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("тип проводки*")]),_c('v-radio-group',{attrs:{"row":"","required":"","rules":_vm.rules.select},model:{value:(_vm.wData.data.wiringType),callback:function ($$v) {_vm.$set(_vm.wData.data, "wiringType", $$v)},expression:"wData.data.wiringType"}},[_c('v-radio',{attrs:{"value":1,"label":"Планируемая"}}),_c('v-radio',{attrs:{"value":2,"label":"Фактическая"}})],1)],1)],1),_c('v-row',{staticStyle:{"margin-bottom":"40px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Приход/Расход*")]),_c('v-radio-group',{attrs:{"row":"","rules":[function (v) { return (v != null && !!v) || 'is required'; }],"required":"","solo":""},on:{"change":function($event){if (_vm.wData.data.project) {
              _vm.funds = _vm.fundsDict[_vm.wData.data.wiringTypeDir].filter(
                function (item) { return item.project == true; }
              );
            } else {
              _vm.funds = _vm.fundsDict[_vm.wData.data.wiringTypeDir].filter(
                function (item) { return item.project == false; }
              );
            }
            _vm.wData.data.funds = ' ';
            _vm.wData.data.article = ' ';
            _vm.wData.data.paymentMethod = ' ';
            _vm.articles = [];
            _vm.paymentMethods = [];}},model:{value:(_vm.wData.data.wiringTypeDir),callback:function ($$v) {_vm.$set(_vm.wData.data, "wiringTypeDir", $$v)},expression:"wData.data.wiringTypeDir"}},[_c('v-radio',{attrs:{"value":"expences","label":"Расход"}}),_c('v-radio',{attrs:{"value":"income","label":"Доход"}}),_c('v-radio',{attrs:{"value":"internalRedistribution","label":"Перераспределение внутреннее","disabled":!!_vm.wData.data.project}}),_c('v-radio',{attrs:{"value":"securityDeposit","label":"Обеспечение"}}),_c('v-radio',{attrs:{"value":"externalRedistribution","label":"Перераспределение внешнее","disabled":!!_vm.wData.data.project}}),_c('v-radio',{attrs:{"value":"reserve","label":"Резерв","disabled":!!_vm.wData.data.project}}),_c('v-radio',{attrs:{"value":"nonfund","label":"Нефондовые","disabled":!!_vm.wData.data.project}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Фонд*","items":_vm.funds,"item-text":"fund","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.data.funds == ' ') {
              _vm.wData.data.funds = null;
            }},"change":function($event){_vm.articles = _vm.articlesDict[_vm.wData.data.funds.id];
            _vm.paymentMethods = [];
            _vm.wData.data.article = ' ';
            _vm.wData.data.paymentMethod = ' ';
            _vm.paymentMethods = [];}},model:{value:(_vm.wData.data.funds),callback:function ($$v) {_vm.$set(_vm.wData.data, "funds", $$v)},expression:"wData.data.funds"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Статья*","items":_vm.articles,"item-text":"article","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.data.article == ' ') {
              _vm.wData.data.article = null;
            }},"change":function($event){_vm.wData.data.paymentMethod = ' ';
            _vm.paymentMethods = [
              { id: 1, text: 'Наличный', value: _vm.wData.data.article.cash },
              {
                id: 2,
                text: 'Безналичный',
                value: _vm.wData.data.article.cashless,
              } ].filter(function (el) { return el.value == true; });}},model:{value:(_vm.wData.data.article),callback:function ($$v) {_vm.$set(_vm.wData.data, "article", $$v)},expression:"wData.data.article"}})],1)],1),_c('v-row',{staticStyle:{"margin-bottom":"40px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Способ оплаты*","items":_vm.paymentMethods,"item-text":"text","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.data.paymentMethod == ' ') {
              _vm.wData.data.paymentMethod = null;
            }}},model:{value:(_vm.wData.data.paymentMethod),callback:function ($$v) {_vm.$set(_vm.wData.data, "paymentMethod", $$v)},expression:"wData.data.paymentMethod"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('date-field',{attrs:{"label":_vm.wData.data.wiringType != 1
              ? 'Дата оплаты*'
              : 'Дата оплаты (планируемая)*',"value":_vm.wData.data.paymentDate,"min-date":_vm.wData.data.wiringType == 1 ? _vm.getTodayDateString() : '2000-01-01',"max-date":"2029-12-31"},on:{"input":function($event){_vm.wData.data.paymentDate = $event}}})],1)],1),_c('register-select-field',{attrs:{"field-title":"Договор","dialog-title":"Выбор договора","reg-config":_vm.customerContractsRegisterConfig,"page-size":"10","default-tab":"0","text-function":function (item) { return item.theme || ''; },"text-search-attribute":"theme","value-object":_vm.wData.data.contractId,"not-null":"true"},on:{"select":function($event){_vm.wData.data.contractId = $event;
        _vm.$forceUpdate();},"clear":function($event){_vm.wData.data.contractId = null;
        _vm.$forceUpdate();}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('register-select-field',{attrs:{"field-title":"Контрагент*","dialog-title":"Выбор контрагент","reg-config":_vm.counterpartiesRegisterConfig,"page-size":"10","default-tab":"0","text-function":function (item) { return item.fullName || ''; },"text-search-attribute":"fullName","value-object":_vm.wData.data.contractor,"not-null":"true"},on:{"select":function($event){_vm.wData.data.contractor = $event;
            _vm.$forceUpdate();},"clear":function($event){_vm.wData.data.contractor = null;
            _vm.$forceUpdate();}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Сумма (руб.)*","dense":"","outlined":"","rules":[
            function (v) { return v == null ||
              v.length === 0 ||
              !!_vm.normalizeNumber(v, 0, 1e13, 2) ||
              'Введите корректную сумму'; } ]},model:{value:(_vm.wData.data.sum),callback:function ($$v) {_vm.$set(_vm.wData.data, "sum", $$v)},expression:"wData.data.sum"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Примечание"},model:{value:(_vm.wData.data.comment),callback:function ($$v) {_vm.$set(_vm.wData.data, "comment", $$v)},expression:"wData.data.comment"}})],1)],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"outlined":"","dense":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]),_c('div',{staticClass:"mt-6"},[_c('v-btn',{attrs:{"outlined":"","onclick":"history.back()"}},[_vm._v("Отменить")]),_c('v-btn',{on:{"click":function($event){if (_vm.editFormValidator(_vm.wData.data)) {
            _vm.editWiring(
              _vm.wData.id,
              _vm.wData.data.budgetYear,
              _vm.wData.data,
              _vm.wData.addit
            );
          } else {
            _vm.errorMessage = 'Не все обязательные поля заполнены';
          }}}},[_vm._v("Сохранить проводку")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }